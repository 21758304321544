const csrfToken = function() {
    return document.querySelector('meta[name=csrf-token]')
        .getAttribute('content');
};

const serializeForm = function(form) {
  let obj = {};
  for (let [key, value] of form) {
    if (obj[key] !== undefined) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }
  return obj;
}

const makeRequest = async function(url, method, requestData, contentType = 'application/json') {
  const headers = { 'Accept': 'application/json',
                    'Content-Type': contentType,
                    'X-CSRF-Token': csrfToken() }

  const options = { method: method,
    headers: headers }

  const data = Object.fromEntries(
    Object.entries(requestData).filter(([_, value]) => value !== undefined)
  );

  if (method.toUpperCase() == "GET") {
    const searchParams = new URLSearchParams(data);
    url = [url, searchParams.toString()].join("?")
  } else
    options.body = JSON.stringify(data)

  const response = await fetch(url, options)
  return await response.json()
}

export { csrfToken, serializeForm, makeRequest };
